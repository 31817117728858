import React from "react"

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Divider } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: `100%`,
    color: theme.palette.text.secondary,
    opacity: `0.85`,
    borderRight: `1px solid #444`,
    borderBottom: `1px solid #333`,
    boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,

  },
  linkText: {
    textDecoration: `none`,
    color: `white`
  }
}));

const Contacts = () => {
  const classes = useStyles();
  return(
    <Paper className={classes.paper}>
      <Divider/>
      <Typography color="primary" align="left" variant="h6" component="h2">Elérhetőségek</Typography>
      <Divider/>
      <Divider/>
      <List dense className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PersonSharpIcon color="" />
          </Avatar>
        </ListItemAvatar>
          <Typography color="textPrimary" displayBlock variant="subtitle2" component="body1">TÖRÖK CSABA <br/> 4722 Nyírmeggyes</Typography>
      </ListItem>
      <a className={classes.linkText} href="tel:0036303304800">
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PhoneAndroidIcon color="action" />
          </Avatar>
        </ListItemAvatar>
        <Typography style={{textDecoration: `underline`}} variant="subtitle2" component="body1">+36 (30) 330-4800</Typography>
      </ListItem>
      </a>
      <a className={classes.linkText} href="mailto:toroktivadar59@gmail.com?Subject=Nehézgépszállítás" target="_top">
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EmailIcon color="action" />
          </Avatar>
        </ListItemAvatar>
        <Typography style={{textDecoration: `underline`}} variant="subtitle2" component="body1">toroktivadar59@gmail.com</Typography>
      </ListItem>
      </a>      
      <a className={classes.linkText} href="tel:0036303304800">
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PrintIcon color="" />
          </Avatar>
        </ListItemAvatar>
        <Typography style={{textDecoration: `underline`}} variant="subtitle2" component="body1">Fax: +36 (44) 409-814</Typography>
      </ListItem>
      </a>      
    </List>

    </Paper>
  )
}

export default Contacts;
