import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import P1 from "./Images/P1"
import P2 from "./Images/P2"
import P3 from "./Images/P3"
import P4 from "./Images/P4"
import P5 from "./Images/P5"
import P6 from "./Images/P6"
import P7 from "./Images/P7"
import P8 from "./Images/P8"
import P9 from "./Images/P9"
import P10 from "./Images/P10"
import P11 from "./Images/P11"
import P12 from "./Images/P12"
import P13 from "./Images/P13"

import Slider from "react-slick"
import "./slick.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    height: "100%",
    color: theme.palette.text.secondary,
  },
  carouselImage: {
    maxHeight: "300px",
  },
}))

const Portfolio = () => {
  const classes = useStyles()
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    sliderPerRow: 1,
    dots: true,
    infinite: true,
    speed: 1000,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          sliderPerRow: 2,
          dots: true,
          infinite: true,
          speed: 1000,
          centerMode: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          sliderPerRow: 1,
          dots: true,
          infinite: true,
          speed: 1000,
          centerMode: true,
          autoplay: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  }

  return (
    <Paper className={classes.paper}>
      <Typography color="primary" align="left" variant="h6" component="h6">
        Korábbi megbízásaink:{" "}
      </Typography>
      <Slider {...settings}>
        <P1 alt="Gépszállítás Nyírbátor traktor" />
        <P2 />
        <P3 />
        <P4 />
        <P5 />
        <P6 />
        <P7 />
        <P8 />
        <P9 />
        <P10 />
        <P12 />
        <P13 />
        <P11 alt="autómentés Mátészalka mentőautó" />
      </Slider>
    </Paper>
  )
}

export default Portfolio
