import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const P2 = () => {
  const data = useStaticQuery(graphql`
    query {
      P2: file(relativePath: { eq: "tartálykocsi-szállítás.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Img fluid={data.P2.childImageSharp.fluid} alt="tartálykocsi-szállítása" />
  )
}
export default P2
