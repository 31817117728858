import React from "react"

import Rescue from '../components/Rescue';
import Tow from '../components/Tow';
import Contacts from '../components/Contacts';
import Layout from "../components/layout"
import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';

import theme from '../theme';
import {useMediaQuery} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {MuiThemeProvider} from '@material-ui/core';

const useStyles = makeStyles(theme => ({  
  root: {
    margin: theme.spacing(3),
    flexGrow: 1,    
  },
}));

const IndexPage = () => {
  const classes = useStyles();
  const greaterThanMd = useMediaQuery('(min-width: 600px)');

  return(
  <MuiThemeProvider theme={theme}>
  <Layout className={classes.sheet}>  
    <SEO title="Autómentés, Gépszállítás | Szabolcs-Szatmár-Bereg megye" />   

    <div className={classes.root}>
    {!!greaterThanMd 
    ? 
      <Grid xs={12} container spacing={2} direction="row" justify="space-evenly" alignItems="stretch">
        <Grid item xs={12} sm={12} md={4} lg={3} container spacing={2} direction="row" justify="space-evenly" alignItems="stretch">
          <Grid item xs={10} sm={8} md={12}><Rescue/></Grid>
          <Grid item xs={10} sm={8} md={12}><Contacts/></Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={9} container spacing={2} direction="row" justify="space-evenly" alignItems="stretch">
          <Grid item xs={10} sm={12}><Tow/></Grid>
        </Grid>        
      </Grid>  
    :    
      <Grid xs={12} container spacing={2} direction="row" justify="space-evenly" alignItems="stretch">
          <Grid item xs={10} sm={9}><Rescue/></Grid>
          <Grid item xs={10} sm={9}><Tow/></Grid>
          <Grid item xs={10} sm={9}><Contacts/></Grid>
      </Grid>  
    }
    </div>  
  </Layout>
  </MuiThemeProvider>
)}

export default IndexPage
