import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#ff5722',
          },
        secondary: {
            main: '#ffc400',
          },
    }
})

export default theme;