import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CallIcon from "@material-ui/icons/Call"
import EmailIcon from "@material-ui/icons/Email"
import Grid from "@material-ui/core/Grid"
import { Divider } from "@material-ui/core"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined"

import Tow2 from "./Images/Tow2"
import Portfolio from "./Portfolio"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    height: `100%`,
    color: theme.palette.text.secondary,
    opacity: `0.85`,
    borderRight: `1px solid #444`,
    borderBottom: `1px solid #333`,
    boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
  },
  linkText: {
    textDecoration: `none`,
  },
  /*
    towIcon: {
      margin: `5% auto 2%`,
    }
    */
}))
const Tow = () => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Divider />
      <Typography color="primary" align="left" variant="h6" component="h2">
        Gépszállítás 24 tonnáig
      </Typography>
      <Divider />
      <Divider />
      <Grid
        container
        spacing={2}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={6} lg={6}>
          <p></p>
          <Typography align="left" color="textPrimary">
            24 Tonnáig vállaljuk szállítását:
          </Typography>
          <List dense>
            <ListItem style={{ margin: `0` }}>
              <ListItemIcon>
                <CheckCircleOutlineOutlinedIcon
                  fontSize="small"
                  color="secondary"
                />
              </ListItemIcon>
              <Typography align="left" color="secondary">
                Mezőgazdasági gépeknek
              </Typography>
            </ListItem>
            <ListItem style={{ margin: `0` }}>
              <ListItemIcon>
                <CheckCircleOutlineOutlinedIcon
                  fontSize="small"
                  color="secondary"
                />
              </ListItemIcon>
              <Typography align="left" color="secondary">
                Építőipari munkagépeknek
              </Typography>
            </ListItem>
            <ListItem style={{ margin: `0` }}>
              <ListItemIcon>
                <CheckCircleOutlineOutlinedIcon
                  fontSize="small"
                  color="secondary"
                />
              </ListItemIcon>
              <Typography align="left" color="secondary">
                Katonai járműveknek
              </Typography>
            </ListItem>
            <ListItem style={{ margin: `0` }}>
              <ListItemIcon>
                <CheckCircleOutlineOutlinedIcon
                  fontSize="small"
                  color="secondary"
                />
              </ListItemIcon>
              <Typography align="left" color="secondary">
                Egyéb szállítható áruknak
              </Typography>
            </ListItem>
          </List>
          <Typography align="left" color="textPrimary">
            Szolgálatatásaink az ország egész területére kiterjednek,
            Szabolcs-Szatmár-Bereg megyéből!
          </Typography>
          <Typography align="left" color="textPrimary"></Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6} alignItems="flex-start">
          <Grid container direction="row" justify="space-around" spacing={1}>
            <Grid item xs={12} md={12} lg={10}>
              <div style={{ margin: `5% 0` }}>
                <Tow2 />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <a className={classes.linkText} href="tel:0036303304800">
                <Button variant="outlined" color="secondary">
                  <CallIcon />
                  <Typography>Hívás</Typography>
                </Button>
              </a>
              <Typography className={classes.linkText} color="textSecondary">
                +36(30)330-4800
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <a
                className={classes.linkText}
                href="mailto:toroktivadar59@gmail.com?Subject=Nehézgépszállítás"
                target="_top"
              >
                <Button variant="outlined" color="primary">
                  <EmailIcon />
                  <Typography>Üzenet</Typography>
                </Button>
              </a>
              <Typography color="textSecondary">
                toroktivadar59@gmail.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Portfolio />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Tow
