import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CallIcon from "@material-ui/icons/Call"
import Divider from "@material-ui/core/Divider"

import Tow1 from "./Images/Tow1"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    height: `100%`,
    color: theme.palette.text.secondary,
    opacity: `0.85`,
    borderRight: `1px solid #444`,
    borderBottom: `1px solid #333`,
    boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
  },
  linkText: {
    textDecoration: `none`,
  },
  /*
    towIcon: {
      margin: `6% auto 0`,
    }
    */
}))

const Rescue = () => {
  const classes = useStyles()
  return (
    <Paper elevation={3} className={classes.paper}>
      <Divider />
      <Typography align="left" variant="h6" color="primary" component="h2">
        Autómentés / Kamionmentés
      </Typography>
      <Divider />
      <Divider />
      <Grid
        spacing={2}
        direction="column"
        justify="space-around"
        alignItems="center"
      >
        <Typography color="textPrimary" align="left">
          Szabolcs-Szatmár-Bereg megye teljes területén!
        </Typography>
        <Typography align="left" variant="body2" color="primary" component="h3">
          (Csenger, Mátészalka, Nyíregyháza, Fehérgyarmat, Kisvárda,
          Vásárosnamény, Nyírbátor, Nyíradony)
        </Typography>
        <Grid item xs={12}>
          <div style={{ margin: `5% 0` }}>
            <Tow1 />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" justify="flex-end">
            <Grid item xs={12}>
              <a className={classes.linkText} href="tel:0036303304800">
                <Button variant="outlined" color="secondary">
                  <CallIcon />
                  <Typography>Hívás</Typography>
                </Button>
              </a>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.linkText} color="textSecondary">
                +36(30)330-4800
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Rescue
